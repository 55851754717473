
import http from '@/utils/http'
import Rest from '@/utils/http/Rest'

export default class Business extends Rest {
  /**
   * 还款计划列表
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getPageData (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/plan/page`,
      params
    })
  }

  // 查询电子章文件盖章序
  getTaskDocSeq (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getTaskDocSeq`,
      params
    })
  }

  // 校验流程是否已经被拒绝
  getCheckForbiddenOrNot (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/duediligence/checkForbiddenOrNot',
      params
    })
  }

  // 查询电子章签章文件
  getSignDetail (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/detail`,
      params
    })
  }

  // 资产负债信息统计-稳诚融资接口
  getFinancingCount (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/manage/getFinancingCount',
      params
    })
  }

  // 资产负债信息统计-金地合同接口
  getContractCount (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/asset/contract/getContractCount',
      params
    })
  }

  // 资产负债信息统计-实控人资产接口
  getCompanyAssetCount (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/company/count/getCompanyAssetCount',
      params
    })
  }

  // 资产负债信息统计-实控人负债接口
  getCompanyLiabilityCount (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/company/count/getCompanyLiabilityCount',
      params
    })
  }

  // 重出股东会决议有效性数据回显
  getShareholderResultValidInfo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/shareholderResultValid/info',
      params
    })
  }

  // 合同文件制作修改股东会决议有效性
  updateByEntity (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/finance/shareholderResultValid/updateByEntity',
      data
    })
  }

  // 续用股东会决议有效性数据回显
  getValidInfo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/shareholderResultValid/getValidInfo',
      params
    })
  }

  // 查询股东决议有效性信息
  getResultValid (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/shareholderResultValid/getContinueValidInfos',
      params
    })
  }

  // 根据融资业务id查询还款计划列表
  getRepaymentPlan (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/repayment/plan/list',
      params
    })
  }

  // 查询抵押物信息
  getPawnList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/result/getPawnList',
      params
    })
  }

  // 查询鲜章签章文件
  getSignDocList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/signDocList`,
      params
    })
  }

  /**
   * get 融资业务详情查看
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  detail (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/detail`,
      params
    })
  }

  /**
   * get 新 融资业务详情查看》文件列表&履历
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  newFinanceDetail (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/finDetail`,
      params
    })
  }

  // 修改 业务签署信息
  saveSigner (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveSigner`,
      data
    })
  }

  // 新增/删除股东协议签署方
  insertOrDeleteShareholder (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/insertOrDeleteShareholder`,
      data
    })
  }

  // 提交合同文件电子章签署人
  saveOrUpdateSignConfig (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveOrUpdateSignConfig`,
      data
    })
  }

  // 保存签章人信息
  saveSignConfig (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveSignConfig`,
      data
    })
  }

  /**
   * 新增还款计划
   * @param finPlanId
   * @returns {AxiosPromise}
   */
  addRepaymentData (finPlanId, data) {
    return http({
      showLoading: true,
      method: 'put',
      url: `/api/finance/repayment/plan/save/batch/${finPlanId}`,
      data
    })
  }

  /**
   * 新增还款计划
   * @param finPlanId
   * @returns {AxiosPromise}
   */
  getSubjectList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/subject`,
      params
    })
  }

  /**
   * 更新还款计划详情
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  info (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/info`,
      data
    })
  }

  /**
   * get 获取业务编号
   * @returns {*|AxiosPromise} 响应
   */
  generate () {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/bizno/generate`
    })
  }

  /**
   * get 查询指定配置组配置项
   * @returns {*|AxiosPromise} 响应
   */
  getBatch (params) {
    return http({
      method: 'get',
      url: '/api/config/independent/typecode/batch',
      params
    })
  }

  /**
   * 融资决议保存
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  save (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/save`,
      data
    })
  }

  // 根据供应商ID查询融资业务
  getGys (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/gys`,
      params
    })
  }

  // 查看会议信息
  getFindById (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/findById`,
      params
    })
  }

  /**
   * post 新增/修改融资业务
   * @returns {*|AxiosPromise} 响应
   */
  business (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/business`,
      data
    })
  }

  /**
   * 融资决议提交
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  submit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/submit`,
      data
    })
  }

  /**
   * 融资决议提交
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  cancel (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/cancel`,
      data
    })
  }

  /**
   * delete 删除融资业务
   * @returns {*|AxiosPromise} 响应
   */
  delete (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delete`,
      params
    })
  }

  /* get 要素落实管理
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getPlanDetail (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/get/${params.loanId}`
    })
  }

  /* get 获取文件定义列表
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getDocument (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/get/document`,
      params
    })
  }

  /* get 获取模板文件Id
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getTemplate (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getTemplate`,
      params
    })
  }

  /**
   * 供应商列表
   * @returns {AxiosPromise}
   */
  getDrop () {
    return http({
      method: 'get',
      url: '/api/company/supplier/drop'
    })
  }

  /**
   *查询要素落实文件清单
   * @returns {AxiosPromise}
   */
  getFileListData (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`,
      params
    })
  }

  /**
   * 保存要素落实信息
   */
  implementSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/save`,
      data
    })
  }

  /**
   *发起要素落实审核流程
   * @returns {AxiosPromise}
   */
  implementCommit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/submit`,
      data
    })
  }

  /**
   *批量上传要素落实文件
   * @returns {AxiosPromise}
   */
  batchUploadFile (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/upload/batch`,
      data
    })
  }

  /**
   *更新融资业务资金方信息
   * @returns {AxiosPromise}
   */
  manageUpdate (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/finance/manage/update',
      data
    })
  }

  /**
   *批量上传要素落实文件
   * @returns {AxiosPromise}
   */
  checkTimeOutOrNot (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/result/checkTimeOutOrNot',
      params
    })
  }

  /**
   *删除要素落实文件
   * @returns {AxiosPromise}
   */
  deleteLmplementFile (data) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delete`,
      data
    })
  }

  /**
   *上传要素落实文件
   * @returns {AxiosPromise}
   * @constructor
   */
  UploadLmplementFile (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/upload`,
      data
    })
  }

  /**
   *上传要素落实文件
   * @returns {AxiosPromise}
   * @constructor
   */
  reUpload (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/finance/filelist/detail/file/reUpload',
      data
    })
  }

  /**
   * 合同文件上传
   * @returns {AxiosPromise}
   * @constructor
   */
  UploadDetailLmplementFile (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/detail/file/upload`,
      data
    })
  }

  /**
   * 要素落实文件上传
   * @returns {AxiosPromise}
   * @constructor
   */
  UploadElementFile (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/upload/element/file`,
      data
    })
  }

  /* DELETE 删除合同文件
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  deleteFileElement (data) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delete/file/element`,
      data
    })
  }

  /**
   *查看要素落实审核履历
   * @param params
   * @returns {AxiosPromise}
   */
  ReviewResume (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/review`,
      params
    })
  }

  /**
   *合同文件保存
   */
  htSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/save`,
      data
    })
  }

  /**
   * 提交合同文件
   */
  htCommit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/submit`,
      data
    })
  }

  /**
   *获取合同配置信息
   * @param data
   * @returns {AxiosPromise}
   */
  gethtconfig (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/generate/getCfgitem`,
      params
    })
  }

  /**
   *远程搜索查询公司股东
   * @param data
   * @returns {AxiosPromise}
   */
  getCompanyShareholdersData (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/generate/getCompanyShareholdersData`,
      params
    })
  }

  /**
   *导出融资需求列表
   * @param data
   * @returns {AxiosPromise}
   */
  financingExport (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/export`,
      params,
      responseType: 'blob'
    })
  }

  /**
   *融资决议文件生成
   * @param data
   * @returns {AxiosPromise}
   */
  generateFile (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/genarate`,
      data
    })
  }

  /**
   *要素落实制作 其他文件上传
   * @param data
   * @returns {AxiosPromise}
   */
  uploadForKeyId (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/uploadForKeyId`,
      data
    })
  }

  // 还款登记

  /**
   * 还款流水信息
   * @param params
   * @returns {AxiosPromise}
   */
  getRepayregisttSummery (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/summery`,
      params
    })
  }

  /**
   * 查看还款登记信息
   * @param params
   * @returns {AxiosPromise}
   */
  getRepayregistRegist (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/regist`,
      params
    })
  }

  /**
   * 还款登记详情页面
   * @param params
   * @returns {AxiosPromise}
   */
  getRepayregistDetail (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/detail`,
      params
    })
  }

  /**
   * 保存还款登记信息
   * @param params
   * @returns {AxiosPromise}
   */
  saveRepayregistInfo (repayplankeyid, data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/${repayplankeyid}/regist`,
      data
    })
  }

  /* get 要素落实审核查询待审核
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getPlantodo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/progress/todo`,
      params
    })
  }

  /* get 要素落实审核查询已审核
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getPlandoto (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/progress/done`,
      params
    })
  }

  /* get 要素落实审核详情
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getFactorDetails (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/element/implement/handle`,
      params
    })
  }

  /* get 生成合同文件
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getGenerate (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/generate`,
      params
    })
  }

  /* post 提交生成合同文件
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  postGenerate (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/generate`,
      data
    })
  }

  /* post 保存生成合同文件
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  setCfgitem (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/generate/setCfgitem`,
      data
    })
  }

  /* DELETE 删除合同文件
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  deleteFile (data, flag) { // flag: 0单调删除，1大类删除
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/detail/file/${flag}`,
      data
    })
  }

  /* DELETE 删除合同文件列
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  deleteFileContracts (data) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/contracts/delete`,
      data
    })
  }

  /* get 查询标准合同文件清单
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getFileList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`,
      params
    })
  }

  /* post 合同文件审核意见提交保存
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  contractSubmit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/contract/submit`,
      data
    })
  }

  /* post 上传合同文件
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  fileupload (params) {
    return `/api/${this.subSys}/${this.module}/upload`
    // http({
    //   method: 'post',
    //   url: `/api/${this.subSys}/${this.module}/upload`,
    //   params
    // })
  }

  /* DELETE 合同文件制作-删除合同文件
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  deleteContract (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delete`,
      params
    })
  }

  /* get 合同文件制作-查询标准合同文件清单
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getContractList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`,
      params
    })
  }

  /* get 合同文件审核办理清单
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getContracthandle (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/contract/handle`,
      params
    })
  }

  /* put 合同文件审核办理
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  approve (data) {
    return http({
      showLoading: true,
      method: 'put',
      url: `/api/${this.subSys}/${this.module}/approve`,
      data
    })
  }

  /* post 合同文件制作-上传合同文件
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  Contractupload (params) {
    return `/api/${this.subSys}/${this.module}/upload`
    // http({
    //   method: 'post',
    //   url: `/api/${this.subSys}/${this.module}/upload`,
    //   params
    // })
  }

  /* get 融资决议审核办理清单
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getResultHandle (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/result/handle`,
      params
    })
  }

  /* put 融资决议审核办理
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  progressApprove (data) {
    return http({
      showLoading: true,
      method: 'put',
      url: `/api/${this.subSys}/${this.module}/approve`,
      data
    })
  }

  /* put 融资决议审核办理
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  updatestatus (data) {
    return http({
      showLoading: true,
      method: 'put',
      url: `/api/${this.subSys}/${this.module}/updatestatus`,
      data
    })
  }

  /* post 自动发送签约
   * @param {*} get 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  sendAutoSign (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/sendSignMsg`,
      params
    })
  }

  // 查询签约结果
  getTaskInfo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getTaskInfo`,
      params
    })
  }

  // 在线签约，鲜章文件，删除文件
  removeSginFiles (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/remove`,
      params
    })
  }

  // 在线签约，鲜章文件，提交文件
  sginFilesupload (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/upload`,
      data
    })
  }

  // 鲜章提交申请认证
  finishTask (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/finishTask`,
      params
    })
  }

  // 查询合同文件签署配置
  getSignConfig (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getSignConfig`,
      params
    })
  }

  // 签约管理 （平台）
  platformPage (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/platform/manage`,
      params
    })
  }

  // 签约管理 （供应商）
  supplierPage (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/supplier/manage`,
      params
    })
  }

  // 签约管理 发起电子章验签
  sginExamine (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/examine`,
      data
    })
  }

  /**
   * 根据平台融资产品Id查询资料清单
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  filelistList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/filelist/notdeploy',
      params
    })
  }

  /**
   * 根据平台融资产品Id查询还款计划
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  getRepayPlans (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/repayment/plan/getRepayPlans',
      params
    })
  }

  /**
   * 重置还款计划表
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  resetRepayPlans (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/finance/repayment/plan/resetRepayPlans',
      data
    })
  }

  /**
   * 自动计算股东会决议有效期止
   * @param data 请求参数
   * @returns {AxiosPromise}
   */eleteFinSigner
  // 单独保存文件（准入尽调）
  saveFinFileListDetails (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/finance/filelist/saveFinFileListDetails',
      data
    })
  }

  // 获取文件最新信息
  fileDetailId (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/filelist/detailid',
      params
    })
  }

  // 文件生成
  getGenerateDo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/generate/do',
      params
    })
  }

  // 提交评审会批复管理
  submitApproval (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/finance/reviewmeeting/submitApproval',
      data
    })
  }

  // 提交评审会批复管理
  examineCommit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/finance/admittance/approval/commit',
      data
    })
  }

  // 回退流程时查询可回退节点
  getNodeList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/admittance/approval/back/getNodeList',
      params
    })
  }

  // 自动计算股东会决议有效期止
  getResultValidTimeEnd (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/shareholderResultValid/getResultValidTimeEnd',
      params
    })
  }

  /**
   * 合同文件 更新是否需要签章
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  updateIsSignStatus (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/updateIsSignStatus`,
      data
    })
  }

  /**
   * 合同文件管理》批量选择电子签章人》删除签章人
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  deleteFinSigner (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/deleteFinSigner`,
      params
    })
  }

  /**
   * 合同文件管理》批量选择电子签章人》预览文件
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  signPreView (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/signPreView`,
      data
    })
  }

  /**
   * 合同分页查询履历
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  contractsRecordsPage (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/contracts/records/page`,
      params
    })
  }

  /**
   * 记录编辑文件合同履历
   * @param finPlanId
   * @returns {AxiosPromise}
   */
  contractsEdit (data) {
    return http({
      showLoading: true,
      method: 'put',
      url: `/api/${this.subSys}/${this.module}/contracts/edit`,
      data
    })
  }

  // 自定义相关接口-------------------------------------------------------------------------------------
  /**
   * 新增 查询业务编号
   * @param finPlanId
   * @returns {AxiosPromise}
   */
  queryServiceOrderNumber (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/queryServiceOrderNumber`,
      data
    })
  }

  /**
   * 提交新增
   * @param finPlanId
   * @returns {AxiosPromise}
   */
  saveReview (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveReview`,
      data
    })
  }

  /**
   * 查询补录合同清单
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  signList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`,
      params
    })
  }

  /**
   * 查询剩余合同补录清单
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  signNotdeploy (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/notdeploy`,
      params
    })
  }

  /*
     *补签内容提交保存
     * @param data 请求参数
     * @returns {AxiosPromise}
     */
  deleteSupplementDocumentTask (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/deleteSupplementDocumentTask`,
      data
    })
  }

  /*
     *查询是否有未完成的签章任务
     * @param data 请求参数
     * @returns {AxiosPromise}
     */
  queryDocumentEditStatus (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/queryDocumentEditStatus`,
      params
    })
  }

  /**
   * 贷后报告  报告期数列表查询
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  periodNum (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/period/num`,
      params
    })
  }

  /**
     * 贷后报告  列表文件删除
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  deleteLoanRostReport (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delete/file`,
      params
    })
  }

  /*
     * 贷后报告  列表文件上传
     * @param data 请求参数
     * @returns {AxiosPromise}
     */
  loanRostReportFileUpload (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/file/upload`,
      data
    })
  }

  /**
     * 贷后报告  报告导出
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  loanRostReportExport (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/export`,
      params,
      responseType: 'blob'
    })
  }

  /**
     * 贷后报告  列表分页查询
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  loanRostReportCount (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/count`,
      params
    })
  }

  /**
     * 贷后报告  电子章查验信息管理
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  electronChapterList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/electron/chapter/list',
      params
    })
  }
}
