
import http from '@/utils/http'
import Rest from '@/utils/http/Rest'

export default class DueDiligence extends Rest {
  /**
   * 查询尽调详情
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getDetail (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/detail`,
      params
    })
  }

  /**
   * 查询尽调融资方案信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getFinPlan (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getFinPlan`,
      params
    })
  }

  /**
   * 暂存融资方案信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  draftSaveFinPlan (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/draftSaveFinPlan`,
      data
    })
  }

  /**
   * 提交融资方案信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  saveFinPlan (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveFinPlan`,
      data
    })
  }

  /**
   * 生成尽调文件
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  generate (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/generate`,
      data
    })
  }

  /**
   * 保存尽调信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  saveDetails (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/save`,
      data
    })
  }

  /**
   * 提交尽调信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  submitDetails (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/submit`,
      data
    })
  }

  /**
   * 查询尽调融资方案》担保动态信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getGuaranteeList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/guarantee/list`,
      params
    })
  }

  /**
   * 删除尽调融资信息》担保动态信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  delGuarantee (data) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/guarantee/delete`,
      data
    })
  }

  /* 查询尽调融资合同及项目信息列表
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getDdFinContracts (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getDdFinContracts`,
      params
    })
  }

  /**
   * 保存尽调融资信息》担保动态
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  guaranteeBatchSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/guarantee/batchSave?businessId=${data.businessId}`,
      data: data.data
    })
  }

  /* 根据融资合同基本信息动态计算付款情况
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getDynamicPaymentInfo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getDynamicPaymentInfo`,
      params
    })
  }

  /**
   * 查询未使用的合同
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getDdBaseContracts (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getDdBaseContracts`,
      params
    })
  }

  /**
   * 查询尽调情况列表
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getExamineInfoList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/detail/list`,
      params
    })
  }

  /**
   * 删除尽调情况列表
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  delExamineInfoList (data) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/detail/delete`,
      data
    })
  }

  /**
   * 保存尽调融资信息》尽调详情
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  detailBatchSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/detail/batchSave?businessId=${data.businessId}`,
      data: data.data
    })
  }

  /**
   * 提交尽调融资信息》尽调详情
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  detailBatchSubmit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/detail/batchSubmit?businessId=${data.businessId}`,
      data: data.data
    })
  }

  /**
   * 批量保存尽调融资合同及项目信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  batchSaveDdFinContracts (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/batchSaveDdFinContracts?businessId=${data.businessId}`,
      data: data.data
    })
  }

  /**
   * 批量提交尽调融资合同及项目信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  batchSubmitDdFinContracts (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/batchSubmitDdFinContracts?businessId=${data.businessId}`,
      data: data.data
    })
  }

  /**
   * 获取尽调监管账户、锁定及通知书寄送信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getDdAccountInfo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/supervise/bank/account/duediligence/getDdAccountInfo',
      params
    })
  }

  /**
   * 保存尽调监管账户、锁定及通知书寄送信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  saveDdAccountInfo (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/finance/supervise/bank/account/duediligence/saveDdAccountInfo',
      data
    })
  }

  /**
   * 保存尽调监管账户、锁定及通知书寄送信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  submitDdAccountInfo (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/finance/supervise/bank/account/duediligence/submitDdAccountInfo',
      data
    })
  }

  /**
   * 获取尽调 企业资产信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getAssetinfoList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/assetinfo/list`,
      params
    })
  }

  /**
   * 保存尽调 企业信息 资产信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  assetinfoBatchSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/assetinfo/batchSave?businessId=${data.businessId}&enterpriseId=${data.enterpriseId}`,
      data: data.data
    })
  }

  /**
   * 提交尽调 企业信息 资产信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  assetinfoBatchSubmit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/assetinfo/batchSubmit?businessId=${data.businessId}&enterpriseId=${data.enterpriseId}`,
      data: data.data
    })
  }

  /**
   * 获取尽调 企业外部地产信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getExtestateinfoList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/extestateinfo/list`,
      params
    })
  }

  /**
     * 提交尽调 企业信息 外部地产信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  extestateinfoBatchSubmit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/extestateinfo/batchSubmit?businessId=${data.businessId}&enterpriseId=${data.enterpriseId}`,
      data: data.data
    })
  }

  /**
     * 保存尽调 企业信息 外部地产信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  extestateinfoBatchSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/extestateinfo/batchSave?businessId=${data.businessId}&enterpriseId=${data.enterpriseId}`,
      data: data.data
    })
  }

  /**
   * 获取尽调 企业负债信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */

  getDebtinfoList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/debtinfo/list`,
      params
    })
  }

  /**
     * 提交尽调 企业信息 企业负债信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  debtinfoBatchSubmit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/debtinfo/batchSubmit?businessId=${data.businessId}&enterpriseId=${data.enterpriseId}`,
      data: data.data
    })
  }

  /**
     * 保存尽调 企业信息 企业负债信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  debtinfoBatchSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/debtinfo/batchSave?businessId=${data.businessId}&enterpriseId=${data.enterpriseId}`,
      data: data.data
    })
  }

  /**
   * 获取尽调 企业诉讼信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getLitigationinfoList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/litigationinfo/list`,
      params
    })
  }

  /**
     * 提交尽调 企业信息 企业诉讼信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  litigationinfoBatchSubmit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/litigationinfo/batchSubmit?businessId=${data.businessId}&enterpriseId=${data.enterpriseId}`,
      data: data.data
    })
  }

  /**
     * 保存尽调 企业信息 企业诉讼信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  litigationinfoBatchSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/litigationinfo/batchSave?businessId=${data.businessId}&enterpriseId=${data.enterpriseId}`,
      data: data.data
    })
  }

  /**
     * 企业信息 企业诉讼  导入文件
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  litigationinfoImport (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/litigationinfo/import`,
      data
    })
  }

  /**
     * 获取尽调 企业信息 财务信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  getFinancialanalysisList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/financialanalysis/list`,
      params
    })
  }

  /**
     * 获取尽调 企业信息 财务信息年份列表
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  financialanalysisGetYears (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/financialanalysis/getYears`,
      params
    })
  }
  /**
     * 企业信息 更具年份查财务信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */

  financialanalysisGetByYear (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/financialanalysis/getByYear`,
      params
    })
  }

  /**
     * 企业信息 财务信息  保存
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  financialanalysisBatchSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/financialanalysis/batchSave?businessId=${data.businessId}&enterpriseId=${data.enterpriseId}`,
      data: data.data
    })
  }

  /**
     * 企业信息 财务信息  提交
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  financialanalysisBatchSubmit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/financialanalysis/batchSubmit?businessId=${data.businessId}&enterpriseId=${data.enterpriseId}`,
      data: data.data
    })
  }

  /**
     * 企业图谱，天眼查实际控制权
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  actualcontrolinfo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/interfaces/actualcontrolinfo',
      params
    })
  }

  /**
     * 企业图谱，股权结构图接口
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  // imequitypenetinfo/tyc/gqjg
  imequitypenetinfoTycGqjg (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/interfaces/imequitypenetinfo/tyc/gqjg',
      params
    })
  }

  // 司法风险相关接口API
  imbusinessriskTycDwtz (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/interfaces/imbusinessrisk/tyc/dwtz',
      params
    })
  }

  // 工商接口相关API
  interfacesZzsyr (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/interfaces/zzsyr',
      params
    })
  }

  // 实际控制权
  interfacesActualcontrolinfo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/interfaces/actualcontrolinfo',
      params
    })
  }
}
