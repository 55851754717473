import Business from './business/business'
import DueDiligence from './business/duediligence'
const SUB_SYSTEM = 'finance'
// 融资业务管理
export const businessManageApi = new Business(SUB_SYSTEM, 'manage')
// 放款计划
export const loanplanAPi = new Business(SUB_SYSTEM, 'loanplan')
// 融资决议管理
export const resolutionAPi = new Business(SUB_SYSTEM, 'result')
export const progressApi = new Business(SUB_SYSTEM, 'business/progress')
export const PlanDetailApi = new Business(SUB_SYSTEM, 'repayment/plan')
// 要素落实管理
export const factorApi = new Business(SUB_SYSTEM, 'business')
export const implementApi = new Business(SUB_SYSTEM, 'element/implement')

// 融资业务资料清单管理
export const fileListApi = new Business(SUB_SYSTEM, 'filelist')
// 合同文件制作
export const contractApi = new Business(SUB_SYSTEM, 'contract/template')
// 还款登记
export const repayregistApi = new Business(SUB_SYSTEM, 'repayregist')
// 合同补录信息
export const cfgitemApi = new Business(SUB_SYSTEM, 'filelist/generate')

// 签约管理
export const signApi = new Business(SUB_SYSTEM, 'sign')
// 融资需求申请
export const financingApplyApi = new Business(SUB_SYSTEM, 'requirement')
// 电子章业务
export const signPosition = new Business(SUB_SYSTEM, 'signPosition')
// 会议管理
export const meetApi = new Business(SUB_SYSTEM, 'meeting')
// 尽调报告
export const dueDiligenceApi = new DueDiligence(SUB_SYSTEM, 'duediligence')
// 尽调报告项目合同信息
export const contractDdApi = new DueDiligence(SUB_SYSTEM, 'contract')
// 评审会
export const reviewMeetingApi = new Business(SUB_SYSTEM, 'reviewmeeting')
// 自定义签章
export const replenishApi = new Business(SUB_SYSTEM, 'replenish/sign')
// 贷后报告
export const loanApi = new Business(SUB_SYSTEM, 'loan/post/report')
